<template>
  <v-container fluid class="mb-16">
    <p class="text-h5 text-left">코드 관리</p>
    <div style="text-align: left; border: 1px solid #aaaaaa; padding: 20px">
      <label style="display: inline-block; width: 100px; text-align: right;">코드명</label>
      <input v-model="param.codeName" type="text" style="width:150px; margin: 0 10px" class="blue_input">
      <label style="display: inline-block; width: 100px; text-align: right;">코드ID</label>
      <input v-model="param.codeId" type="text" style="width:150px; margin: 0 10px" class="blue_input">
      <button type="button" class="pp_s" @click="searchCodeMasters">검색</button>
    </div>

    <v-row>
      <v-col cols="4">
        <v-simple-table
					fixed-header
					height="380px"
					class="popup-table mt-6"
					style="width: 100%; border-top: 2px solid #2c3976"
				>
          <colgroup>
            <col width="10%">
            <col width="40%">
            <col width="20%">
            <col width="10%">
          </colgroup>
          <thead>
          <tr>
            <th class="td_dark_blue">NO.</th>
            <th class="td_dark_blue">코드명</th>
            <th class="td_dark_blue">코드ID</th>
            <th class="td_dark_blue">
              <v-btn small elevation="0" color="primary" @click="addItem">추가</v-btn>
            </th>
          </tr>
          </thead>
          <thead v-if="loading">
          <tr>
            <th colspan="4" class="td_loading">
              <v-progress-linear
                indeterminate
                color="#0f2585"
              ></v-progress-linear>
            </th>
          </tr>
          </thead>
          <tbody v-if="codeMasters.length > 0">
          <template v-for="(item,index) in codeMasters">
            <tr :key="index" :class="selectedIndex === index ? 'selected' : ''">
              <td class="td_blue cen bo" @click="itemSelect(item, index)">{{ index + 1 }}</td>
              <td class="td_blue cen bo" @click="itemSelect(item, index)">{{ item.codeName }}</td>
              <td class="td_blue cen bo" @click="itemSelect(item, index)">{{ item.codeId }}</td>
              <td class="td_blue cen bo">
                <v-btn small elevation="0" color="#bfc2c5" @click="removeItem(item, index)">삭제</v-btn>
              </td>
            </tr>
          </template>
          </tbody>
          <tbody v-else>
          <tr>
            <td class="no-data" colspan="4">데이터를 찾을 수 없습니다.</td>
          </tr>
          </tbody>

        </v-simple-table>

        <table class="popup-table mt-6" style="width: 100%">
          <colgroup>
            <col width="20%">
            <col width="30%">
            <col width="20%">
            <col width="30%">
          </colgroup>
          <tbody>
          <tr>
            <th class="td_dark_blue"><label for="txtAuthCode">코드ID</label></th>
            <td class="td_blue cen bo">
              <input
                v-model="selectedItem.codeId"
                type="text"
                style="width:96%"
                class="blue_input"
                id="txtAuthCode"
              />
            </td>
            <th class="td_dark_blue"><label for="txtAuthDesc">코드명</label></th>
            <td class="td_blue cen bo">
              <input
                v-model="selectedItem.codeName"
                type="text"
                style="width:96%"
                class="blue_input"
                id="txtAuthDesc"
              />
            </td>
          </tr>
          <tr>
            <th class="td_dark_blue"><label for="txtUseYn">사용여부</label></th>
            <td class="td_blue cen bo">
              <input
                v-model="selectedItem.useYn"
                class="ml-4 mr-2"
                type="checkbox"
                id="txtUseYn"
                true-value="Y"
                false-value="N"
              />
            </td>
            <th class="td_dark_blue"></th>
            <td class="td_blue cen bo">
            </td>
          </tr>
          </tbody>

        </table>
        <div class="text-right mt-2">
          <v-btn small class="mr-2" elevation="0" color="primary" @click="saveItem" :disabled="selectedIndex < 0">저장
          </v-btn>
        </div>
      </v-col>

      <v-col cols="8">
        <v-simple-table
					fixed-header
					height="380px"
					class="popup-table mt-6"
					style="width: 100%; border-top: 2px solid #2c3976"
				>
          <colgroup>
            <col width="5%">
            <col width="40%">
            <col width="20%">
            <col width="7%">
            <col width="20%">
            <col width="8%">
          </colgroup>
          <thead>
          <tr>
            <th class="td_dark_blue">NO.</th>
            <th class="td_dark_blue">상세코드명</th>
            <th class="td_dark_blue">상세코드</th>
            <th class="td_dark_blue">정렬</th>
            <th class="td_dark_blue">사용여부</th>
            <th class="td_dark_blue">
              <v-btn small elevation="0" color="primary" :disabled="selectedIndex < 0" @click="addDetailItem">추가</v-btn>
            </th>
          </tr>
          </thead>
          <thead v-if="detailLoading">
          <tr>
            <th colspan="6" class="td_loading">
              <v-progress-linear
                indeterminate
                color="#0f2585"
              ></v-progress-linear>
            </th>
          </tr>
          </thead>
          <tbody v-if="codeDetails.length > 0">
          <template v-for="(item,index) in codeDetails">
            <tr :key="index">
              <td class="td_blue cen bo" @click="itemSelectDetail(item, index)">{{ index + 1 }}</td>
              <td class="td_blue cen bo" @click="itemSelectDetail(item, index)">{{ item.codeDetailName }}</td>
              <td class="td_blue cen bo" @click="itemSelectDetail(item, index)">{{ item.codeDetailId }}</td>
              <td class="td_blue cen bo" @click="itemSelectDetail(item, index)">{{ item.order }}</td>
              <td class="td_blue cen bo" @click="itemSelectDetail(item, index)">{{ item.useYn }}</td>
              <td class="td_blue cen bo">
                <v-btn small elevation="0" color="#bfc2c5" @click="removeDetailItem(item, index)">삭제</v-btn>
              </td>
            </tr>
          </template>
          </tbody>
          <tbody v-else>
          <tr>
            <td class="no-data" colspan="6">데이터를 찾을 수 없습니다.</td>
          </tr>
          </tbody>

        </v-simple-table>
				<table class="popup-table mt-6" style="width: 100%">
					<colgroup>
						<col width="20%">
						<col width="30%">
						<col width="20%">
						<col width="30%">
					</colgroup>
					<tbody>
					<tr>
						<th class="td_dark_blue"><label for="txtAuthDesc">상위코드</label></th>
						<td class="td_blue cen bo">
							<input
								v-model="selectedDetail.codeId"
								type="text"
								style="width:96%"
								class="blue_input"
								id="txtAuthDesc"
								disabled
							/>
						</td>
            <td class="td_blue cen bo" colspan="2"></td>
					</tr>
					<tr>
            <th class="td_dark_blue"><label for="txtAuthName">상세코드ID</label></th>
            <td class="td_blue cen bo">
              <input
                  v-model="selectedDetail.codeDetailId"
                  type="text"
                  style="width:96%"
                  class="blue_input"
                  id="txtAuthName"
                  :readonly="!selectedDetail.isNew"
              />
            </td>
            <th class="td_dark_blue"><label for="txtAuthCode">상세코드명</label></th>
            <td class="td_blue cen bo">
              <input
                  v-model="selectedDetail.codeDetailName"
                  type="text"
                  style="width:96%"
                  class="blue_input"
                  id="txtAuthCode"
              />
            </td>
					</tr>
					<tr>
            <th class="td_dark_blue"><label for="txtAuthName">정렬</label></th>
            <td class="td_blue cen bo">
              <input
                  v-model="selectedDetail.order"
                  type="text"
                  style="width:96%"
                  class="blue_input"
                  id="txtAuthName"
              />
            </td>
						<th class="td_dark_blue"><label for="txtUseYn">사용여부</label></th>
						<td class="td_blue cen bo">
							<input
								v-model="selectedDetail.useYn"
								class="ml-4 mr-2"
								type="checkbox"
								id="txtUseYn"
								true-value="Y"
								false-value="N"
							/>
						</td>
					</tr>
					</tbody>

				</table>
				<div class="text-right mt-2">
					<v-btn small class="mr-2" elevation="0" color="primary" @click="saveDetailItem" :disabled="selectedDetailIndex < 0">저장
					</v-btn>
				</div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'MenuManage',
  data() {
    return {
      param: {
				codeName: null,
				codeId: null
      },
      loading: false,
      detailLoading: false,
      items: [],
      menuList: [],
			codeMasters: [],
			codeDetails: [],
      selectedItem: {},
      selectedIndex: -1,
			selectedDetail: {},
			selectedDetailIndex: -1,
      checkAll: 'N'
    }
  },
  watch: {
    checkAll(val) {
      for (let menu of this.menuList) {
        menu.chk = val
      }
    }
  },
  mounted() {
    this.searchCodeMasters()
  },
  methods: {
		searchCodeMasters() {
			this.loading = true
			this.$http.get('/api/sys/code/master', {params: this.param})
				.then(({ data }) => {
					this.codeMasters = data
          this.selectedDetail = {}
          this.selectedDetailIndex = -1
				})
				.finally(() => this.loading = false)
		},
		searchCodeDetails() {
			this.detailLoading = true
			this.$http.get(`/api/sys/code/detail/${this.selectedItem.codeId}`)
				.then(({ data }) => {
					this.codeDetails = data
          this.selectedDetail = {}
          this.selectedDetailIndex = -1
				})
				.finally(() => this.detailLoading = false)
		},
    itemSelect(item, index) {
      this.selectedItem = item
      this.selectedIndex = index
      this.searchCodeDetails()
    },
		itemSelectDetail(item, index) {
      this.selectedDetail = item
      this.selectedDetailIndex = index
    },
    addItem() {
      const newItem = {order: this.codeMasters.length + 1, isNew: true, useYn: 'Y'}
      this.codeMasters.push(newItem)
      this.selectedItem = this.codeMasters[this.codeMasters.length - 1]
      this.selectedIndex = this.codeMasters.length - 1
    },
    addDetailItem() {
      const newItem = {
        codeId: this.selectedItem.codeId,
        order: this.codeDetails.length + 1,
        isNew: true,
        useYn: 'Y'
      }
      this.codeDetails.push(newItem)
      this.selectedDetail = this.codeDetails[this.codeDetails.length - 1]
      this.selectedDetailIndex = this.codeDetails.length - 1
    },
    saveItem() {
      this.$http.post('/api/sys/code/master', this.selectedItem)
      .then(({ data }) => {
        if (data === 'success') {
          alert('저장되었습니다.')
          this.searchCodeMasters()
        }
      })
    },
    removeItem(item, index) {
      if (item.isNew) {
        this.items.splice(index, 1)
      } else {
        if (confirm('선택한 항목을 삭제하겠습니까?')) {
          this.$http.post('/api/sys/code/master/remove', item)
          .then(({data}) => {
            if (data === 'success') {
              alert('삭제되었습니다.')
              this.search()
            }
          });
        }
      }
    },
    saveDetailItem() {
      this.$http.post('/api/sys/code/detail', this.selectedDetail)
          .then(({ data }) => {
            if (data === 'success') {
              alert('저장되었습니다.')
              this.searchCodeMasters()
            }
          })
    },
    removeDetailItem(item, index) {
      if (item.isNew) {
        this.items.splice(index, 1)
      } else {
        if (confirm('선택한 항목을 삭제하겠습니까?')) {
          this.$http.post('/api/sys/code/detail/remove', item)
              .then(({data}) => {
                if (data === 'success') {
                  alert('삭제되었습니다.')
                  this.search()
                }
              });
        }
      }
    },
  }
}
</script>
